window.addEventListener('load', function (){
    setNavigation();
});

function setNavigation() {
    const divToAppendTo = document.getElementById('main-header').getElementsByClassName('aem-Grid')[0];

    divToAppendTo.insertAdjacentHTML(
        "afterbegin",
        `<input class="u-show-mobile-tablet-only" type="checkbox" /> 
                       <div class="icon-open u-show-mobile-tablet-only"></div>
                       <div class="icon-close u-show-mobile-tablet-only"></div>
                  `);

    const stickyHeader = document.getElementById('main-header');
    const stickyHeaderEmptyDiv = document.createElement('div');
    stickyHeaderEmptyDiv.setAttribute("id", "stickyHeaderEmptyDiv");
    stickyHeader.insertAdjacentElement("afterend", stickyHeaderEmptyDiv);
    stickyHeader.classList.add('header--fixed');
    stickyHeader.style.position = 'fixed';
    stickyHeaderEmptyDiv.style.height = stickyHeader.offsetHeight.toString() + 'px';
}
